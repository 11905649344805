import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./src/styles/global.scss";

export const wrapRootElement = ({ element }) => {
  return (
    <>
      <head>
        <meta id="tempID" property="tempTitle" content="temp" />
      </head>
      {element}
    </>
  );
};

//export { wrapRootElement };
